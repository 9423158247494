import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Kiji from "@components/kiji-link";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <section className='honbun'>
      <p>{`本教材では、今後出てくる「コンプ」や「EQ」といったプラグインは、Studio Oneに付属のシンプルな「channel Strip」を使うことを強く推奨しています。`}</p>
      <p>{`理由は、初めから多くのパラメータ（設定できる項目）を持つ複雑なプラグインを使うと、`}</p>
      <p>{`「最短距離で無駄なくMIXを仕上げる」`}</p>
      <p>{`という目標からは逆行してしまうからです（特に初心者は）。`}</p>
      <p>{`なぜなら、パラメータが多いと`}<br parentName="p"></br>{`
`}{`「このパラメータは何や？どうやって使うんや？どう変わったんや？？」`}<br parentName="p"></br>{`
`}{`と、いちいち立ち止まって悩まなければならないからです。`}</p>
      <p>{`もちらん、`}<br parentName="p"></br>{`
`}{`「そんなカンタンな物じゃ、ダメなんじゃないの？」`}<br parentName="p"></br>{`
`}{`と思われるかも知れませんが、`}<br parentName="p"></br>{`
`}{`各所に明記しております通り、見本曲では`}<br parentName="p"></br>{`
`}{`チャンネルストリップしか使っておりません。`}<br parentName="p"></br>{`
`}{`そして、お聞きの通り、結果も十二分に良いものです。`}</p>
      <p>{`このように、「シンプルでサクサク進んで、音もいい方法」というのはあります。「MIXは複雑でなくてはならない」というのは、最も僕らを苦しめる間違った思い込みです。`}<br parentName="p"></br>{`
`}{`それを実感していただくために、まずはチャンネルストリップをお勧めしているのです。そうすれば、パラメータでいちいち悩んで作業が中断され、進まずにイライラすることが大幅に減ります。`}</p>
      <p><strong parentName="p" {...{
          "className": "aka"
        }}>{`なお、「ChannelStrip」にバグを見つけているので、必ず一度ご覧ください。`}</strong>{`対処方法が有ります。：`}<Kiji kagi='bug' tuika_class='' mdxType="Kiji" /></p>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      